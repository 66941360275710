import React from "react";

import { Box, Header, ListItem, Text } from "Atoms";

export const TermsAndConditionsTextEn: React.FC = () => {
    return (
        <>
            <Header as="h1">Qopla terms of use for guests</Header>
            <Text>Last updated: January 2025</Text>
            <Box pt="4">
                <Header as="h2">1. General</Header>
                <Text>
                    We reserve the right to create and enforce rules, processes, actions, and tools to ensure that the
                    platform is used legally and in accordance with our policies and these terms. If the platform is
                    used illegally or in a way that violates our policies or these terms, we reserve the right to review
                    and take action. Read more about our rights to act against customers in section 12. <br />
                    <br />
                    By using the platform, you confirm that you have read and accepted these terms and that:
                    <ListItem>If you order alcoholic beverages, you are over 18 years old.</ListItem>
                    <ListItem>You have the right to enter into agreements; and</ListItem>
                    <ListItem>
                        You are the holder of the bank/credit card or bank account used for purchases on the platform.
                    </ListItem>
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">2. The Platform and Its Offerings</Header>
                <Text>
                    Qopla mediates food orders from the user to the selected restaurant, hereinafter also referred to as
                    the Partner. Each restaurant is responsible for processing and delivering the order according to its
                    own terms. Qopla does not offer its own delivery services; delivery is either by pickup or through
                    the restaurant's own delivery options. <br />
                    <br />
                    Qopla only mediates the service between the restaurant and the customer. Purchase agreements are
                    entered directly between the customer and the restaurant, meaning Qopla is not a party to the
                    agreement. Qopla only facilitates the transaction. Thus, Qopla has no obligations for delivery,
                    payment, or other terms arising from the agreement between the customer and the restaurant, but acts
                    only as an intermediary in accordance with the agency agreement.
                    <br />
                    <br />
                    Qopla is also not responsible for:
                    <ListItem>The quality or content of the food.</ListItem>
                    <ListItem>Deliveries performed by restaurants.</ListItem>
                    <ListItem>Any payment processing errors caused by third-party providers.</ListItem>
                    <ListItem>Delayed or failed delivery due to technical problems.</ListItem>
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">3. Orders</Header>
                <Text>
                    On the platform, you can order products primarily for pickup, dine-in, and, if the restaurant offers
                    it, home delivery. You can place orders in Swedish and English, and once you have placed an order, a
                    confirmation will be sent to the email address you provided at checkout. The confirmation will
                    include all the information about the order as well as any additional terms and conditions that
                    apply.
                    <br />
                    <br />
                    If you have chosen pickup, you are responsible for collecting the order at the location specified at
                    the time of ordering. If home delivery is offered and you have chosen this option, it is handled by
                    the restaurant itself, and Qopla takes no responsibility for this.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    3.1 How to Place an Order
                </Header>
                <Text>
                    The customer visits the restaurant's website and clicks on online ordering, and all orders are made
                    on the platform by first selecting whether you want to pick up the food, eat on-site, or have it
                    delivered if possible. You can also choose to make a pre-order for delivery or pickup at a later
                    time, up to seven (7) days in advance. Then, you select the products you want to purchase, add them
                    to your cart, and complete the order at checkout.
                    <br />
                    <br />
                    Please note that Qopla never accepts product orders via email or chat, and payments are always made
                    on the platform.
                    <br />
                    <br />
                    After you have placed an order, you will receive an email confirming that Qopla has received your
                    order. We will get back to you as soon as the order has been accepted. Once the order has been
                    accepted and the agreement is concluded, this will be displayed on the platform. The estimated time
                    for pickup will also be indicated there. Please note that an order cannot be canceled by you after
                    it has been accepted. Until then, you can change or cancel the order at any time.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    3.2 Special Instructions for Orders
                </Header>
                <Text>
                    You have the right to leave special instructions for an order or a product(s) in the order. To the
                    extent possible and commercially reasonable, these special instructions will be followed. However,
                    neither the Partner nor Qopla is obliged to replace or refund an order and/or product(s) that has
                    been accompanied by special instructions from you.
                    <br />
                    <br />
                    For special instructions related to allergens, allergic reactions, dietary restrictions, or dietary
                    requirements, please contact the relevant Partner directly according to sections 7.2–3 below before
                    placing your order. If you are particularly sensitive to contact with certain allergens, please note
                    that neither Qopla nor the Partner can guarantee that your product, despite any special packaging,
                    will not be delivered in the same transport bag as products containing such allergens.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    3.3 Right of Withdrawal and Complaints
                </Header>
                <Text>
                    In accordance with the Distance Contracts Act, the right of withdrawal does not apply to purchases
                    of meals, foodstuffs, or other products with a short shelf life or that can quickly deteriorate or
                    become old, or to purchases of products that were sealed upon delivery but where the seal has been
                    broken by you and the product cannot be returned due to health or hygiene reasons. By placing an
                    order, you give your explicit consent that the delivery service will begin as soon as your order has
                    been accepted and that you thereby agree that the right of withdrawal is forfeited when the service
                    is completed.
                    <br />
                    <br />
                    Complaints regarding incorrect or defective goods must be made within a reasonable time after the
                    defect has been discovered. See further under section 8 below.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">4. Prices and Fees</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    4.1 Price
                </Header>
                <Text>
                    Current prices for products, delivery fees, and service fees are displayed on the platform when you
                    visit a restaurant partner's page. Qopla reserves the right to correct any obvious errors in prices
                    or other information. All prices on the platform are inclusive of VAT.
                    <br />
                    <br />
                    Prices can be influenced by offers that may be available to specific partners, the ordered products,
                    product categories, or the order amount, and can be provided at the expense of Qopla or the partner
                    (more information can be found in section 5). Additionally, Qopla may offer bonus programs,
                    promotions, or similar from time to time.
                    <br />
                    <br />
                    In some cases, Qopla may set the price for products from partners themselves and may also limit the
                    partners' ability to offer products on the platform. Qopla reserves the right to update prices
                    and/or content and other information about the products. It is your responsibility to check the
                    applicable prices and conditions at the time you place your order.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    4.2 Minimum Order Value
                </Header>
                <Text>
                    When purchasing through the platform, there may be a minimum order amount. This amount can vary
                    between different partners but is always displayed in your cart before you complete the order. If
                    the value of your order is lower than the minimum amount, you will pay the difference. This amount
                    can be deducted from any refund upon return.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">5. Payment</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    5.1 How Payment Works
                </Header>
                <Text>
                    Unless otherwise agreed, payment for your order must be made to Qopla at the time of ordering using
                    a bank/credit card, Swish, or other payment methods offered on the platform. Valid vouchers or gift
                    cards can also be used to pay for all or part of your order. The available payment methods are
                    displayed at checkout.
                    <br />
                    <br />
                    Please note that Qopla cannot guarantee that all payment methods are always available and that they
                    may sometimes be temporarily unavailable or limited. Qopla reserves the right to offer more payment
                    methods and/or remove existing payment methods at any time.
                    <br />
                    <br />
                    With your consent, your bank/credit card or payment details can be stored with our third-party
                    payment providers for future orders. However, Qopla does not store these details.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    5.2 No Proprietary Payment Services
                </Header>
                <Text>
                    Qopla does not provide its own payment services to you or third parties. All online payments are
                    processed through the external payment service providers presented on the platform. By making a
                    purchase through the platform, you therefore accept, in addition to these Terms, the terms of the
                    relevant external payment service provider for your payment. For more information, visit the
                    respective external payment service provider's website.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    5.3 Gift Cards and Discount Codes
                </Header>
                <Text>
                    Qopla offers gift cards and discount codes.
                    <br />
                    <br />
                    <strong>Gift Cards:</strong> Digital gift cards can be purchased and used for orders through the
                    platform. They are valid for the period specified at issuance and cannot be redeemed for cash. Qopla
                    does not hold the funds for the gift card balance; these are paid directly to the relevant
                    restaurant.
                    <br />
                    <br />
                    <strong>Discount Codes:</strong> Discount codes may be offered for specific campaigns and time
                    periods. The conditions for each discount code are specified at issuance.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">6. Terms of Purchase</Header>
                <Text>
                    If you have chosen pickup or to dine on-site, the product(s) must be collected by you at the
                    partner's restaurant at the time indicated when ordering on the platform. Depending on the type of
                    product(s) you have ordered, the partner may require you to identify yourself with valid ID upon
                    pickup. The partner may also refuse to hand over the order if certain conditions are met.
                    <br />
                    <br />
                    Some products may deteriorate if you do not collect them at the indicated time. Qopla, or, where
                    applicable, the partner, is not responsible for any deterioration that may occur if the products are
                    not picked up on time.
                    <br />
                    <br />
                    If the order is not picked up within a reasonable time, usually 30 minutes after the indicated
                    pickup time, you may be charged the full price for the products if they cannot be resold and/or for
                    other costs that Qopla or the partner incurred in connection with your order.
                    <br />
                    <br />
                    Once you have picked up the order, the risk of loss or damage to the products passes to you. Neither
                    Qopla nor the partner is responsible for any loss or damage to the products that occurs after
                    pickup.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">7. Content and Quality</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    7.1 General
                </Header>
                <Text>
                    Meals and other food items offered on the platform are prepared and packaged by our partners. Qopla
                    is not involved in the preparation, cooking, or packaging of the products. The partner is
                    responsible for being registered as a food business with local authorities and for complying with
                    the requirements of the Swedish Food Agency (Livsmedelsverket).
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    7.2 Information About Content
                </Header>
                <Text>
                    The restaurant that prepares or cooks meals via the platform provides a list of ingredients and
                    other information about the meal. This is presented on the platform, but Qopla cannot guarantee that
                    the information is always correct and up to date, as the restaurant may have changed or added
                    ingredients without our knowledge. For more information on allergens, ingredients, or the origin of
                    the ingredients, please contact the restaurant directly via the contact details on the respective
                    restaurant's partner page by clicking on "Contact the restaurant." Unless otherwise stated, the
                    meals are intended to be consumed immediately after delivery, and we are not responsible for the
                    quality of the meal if it is eaten later.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    7.3 Specific Terms and Conditions for Products
                </Header>
                <Text>
                    Products with age restrictions, such as alcohol, may only be ordered by you if you are 18 years or
                    older. When serving "Eat here," the restaurant will conduct an age and ID check of the recipient to
                    confirm that they are the same person as the orderer. If the recipient does not pass the age and ID
                    check, neither Qopla nor the restaurant will deliver/hand over the product, and it will be returned
                    to the restaurant. If the order contains other products that do not have age restrictions, these
                    can, however, be delivered/handed over.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">8. Reklamation</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    8.1 Complaints
                </Header>
                <Text>
                    Qopla refers all complaints directly to the partner restaurant. If the restaurant does not respond,
                    Qopla will make a decision regarding the complaint. However, it is primarily the restaurant's
                    responsibility to handle complaints and the right of withdrawal since the purchase agreement is made
                    directly between the customer and the restaurant.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    8.2 Refunds for Retur
                </Header>
                <Text>
                    After Qopla or the partner has approved your return, the refund will be processed using the same
                    payment method you used for the order, unless otherwise agreed or specified in these terms. If the
                    product has been used, handled, or packaged carelessly by you upon return, or if you have handled
                    the product more than necessary to determine its characteristics or functionality, Qopla or the
                    partner has the right to deduct an amount equivalent to the product's depreciation from the refund
                    amount.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">9. Customer reviews</Header>
                <Text>
                    Customers have the opportunity to rate and review restaurants and products. To ensure that ratings
                    and reviews come from customers who have actually purchased products from the respective restaurant,
                    customers can only rate and write reviews after the order has been delivered and only for a limited
                    time.
                    <br />
                    <br />
                    Reviews left on the platform must be free from racist, sexist, offensive, harassing, or otherwise
                    threatening or illegal content. They must also not infringe on third-party rights (including
                    intellectual property rights). We review reviews, and if a review is deemed illegal, violates these
                    terms, or otherwise does not align with our values, we have the right to remove it from the
                    platform. If a customer frequently leaves reviews with obviously illegal content, we also have the
                    right to temporarily suspend the customer from the platform after a warning has been issued.
                    <br />
                    <br />
                    We will always provide the customer with a clear and specific reason for our moderation decision. A
                    temporary suspension will primarily last for a maximum of 15 days. If the behavior is deemed
                    serious, we may decide to permanently suspend the customer.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">10. Processing of Personal Data and Data Protection</Header>
                <Text>
                    We process your personal data in accordance with the General Data Protection Regulation (GDPR),
                    which you can read more about in our privacy policy. By accepting the terms, you are aware that we
                    may send emails and/or SMS related to your order.
                    <br />
                    <br />
                    We collect, store, and process your personal data solely for the purpose of delivering our services
                    and improving your user experience.
                    <br />
                    <br />
                    Depending on your choice of payment method, external payment service providers may also process your
                    personal data according to their own terms.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">11. Qopla's Rights</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    11.1 Respect for the Platform
                </Header>
                <Text>
                    Products sold via the platform are intended for personal use, and commercial use of the products or
                    the platform is strictly prohibited. By accepting these terms, you agree not to resell or transfer
                    any rights or obligations in relation to Qopla.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    11.2 Respect for Other Intellectual Property Rights
                </Header>
                <Text>
                    All copyrights, trademarks, trade names, logos, and other intellectual or industrial property rights
                    belonging to us, our licensors, or our partners, and that are found on the platform or in Qopla's
                    marketing material, may not be reproduced, distributed, sold, used, posted, published, transferred,
                    modified, altered, copied, or used without our written permission.
                    <br />
                    <br />
                    Use of these rights for commercial purposes or otherwise without our approval is strictly
                    prohibited, except to the extent necessary to use the platform according to these terms.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    11.3 Respect for Proper
                </Header>
                <Text>
                    You must not, for any purpose, manipulate, attempt to gain unauthorized access to, alter, hack, or
                    otherwise adjust our property, including our materials, software, hardware, source code, or
                    information.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">12. Qopla's Right to Act Against Customers</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    12.1 Temporary or Immediate Suspension
                </Header>
                <Text>
                    If you frequently provide content on the platform that we, at our discretion or after a report, deem
                    to be clearly illegal (e.g., offensive, harassing, hate speech, or otherwise clearly in violation of
                    these terms), we reserve the right to temporarily suspend you from the platform for a reasonable
                    period after issuing a prior warning. We will always provide you with a clear and specific reason
                    for our decision, and the suspension will primarily last no more than 15 days. If your behavior is
                    deemed serious, we may decide to permanently suspend you, and you will always receive an email
                    notification about this. Regardless of the severity of the behavior, we may always seek compensation
                    for costs and damages incurred by us or our partners due to your behavior.
                    <br />
                    <br />
                    We also reserve the right to suspend accounts and terminate ongoing or pre-ordered orders with
                    immediate effect if you violate these terms, misuse our services, or otherwise cause harm or
                    inconvenience to us, our partners, or our other customers through the use of the platform and our
                    services. For example, if we have reasonable grounds to suspect that you are placing repeated false
                    orders or complaints. Account suspensions can occur immediately, and you will always receive a
                    notification via email. Upon suspension, we will reimburse you for incomplete orders, reduced by the
                    costs incurred by us or the partner due to the suspension.
                    <br />
                    <br />
                    Finally, we reserve the right to disable the use of email addresses and/or payment information
                    previously linked to suspected or confirmed fraud on the platform.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">13. Miscellaneous</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    13.1 Platform Availability
                </Header>
                <Text>
                    We strive to provide you with the best possible customer experience, but we cannot guarantee that
                    the platform will always be secure, available, uninterrupted, or free from errors, technical
                    problems, defects, or viruses. Temporary disruptions may occur on the platform due to scheduled
                    maintenance, internet or communication outages, force majeure, or other factors beyond our control.
                    We are not responsible for delays, failed deliveries, errors, losses, or damages arising from such
                    issues, including problems that naturally occur when using the platform, the internet, or electronic
                    communication.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    13.2 Change of Terms
                </Header>
                <Text>
                    Qopla reserves the right to change these terms at any time. We will notify you of changes by
                    publishing the updated terms on our website and in the mobile app. Continued use of our services
                    after the changes take effect implies that you accept the new terms.
                    <br />
                    <br />
                    You understand and agree that we regularly update the platform, including our website, to ensure its
                    security and improve your user experience. These terms also apply after such updates to the mobile
                    app have been implemented.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">14. Applicable Law and Dispute Resolution</Header>
                <Text>
                    The terms are established in accordance with Swedish law and shall be interpreted accordingly.
                    <br />
                    <br />
                    Disputes between you and Qopla shall primarily be resolved by agreement. If we cannot reach an
                    agreement and you have purchased products via the platform as a private person/consumer, the dispute
                    shall be decided by the National Board for Consumer Disputes (ARN). Qopla follows ARN's
                    recommendations. In other cases, the dispute shall be finally decided by a general court.
                    <br />
                    <br />
                    As a private person/consumer residing within the EU, you also have the right to use the EU
                    Commission's Online Dispute Resolution (ODR) platform to resolve disputes via the internet. The ODR
                    platform can be found{" "}
                    <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">here.</a>
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">15. Contact Information</Header>
                <Text>
                    If you have any questions about the Platform, Products, or orders, or if you experience any issues
                    with your account, you are welcome to contact our customer service via email at support@qopla.com
                    after a purchase has been made.
                </Text>
            </Box>
        </>
    );
};
