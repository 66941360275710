import React from "react";
import { capitalize } from "lodash";

import { Button, Flex, Header } from "Atoms";
import { Languages, useLanguage } from "LanguageProvider";
import { modalNames } from "Constants";
import { TermsAndConditionsTextSv } from "./TermsAndConditionsTextSv";
import { TermsAndConditionsTextEn } from "./TermsAndConditionsTextEn";

const TermsAndConditions: React.FC<{ closeModal: (modalName: string) => void }> = ({ closeModal }) => {
    const { translate, userLanguage } = useLanguage();
    window.scrollTo(0, 0);
    return (
        <Flex flexDirection="column" width="100%" alignItems="center">
            <Flex
                w="100%"
                h="250px"
                backgroundImage={`url("https://qopla.s3.eu-west-1.amazonaws.com/qopla/q_bg.png")`}
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="repeat"
                justifyContent="center"
                alignItems="center"
            >
                <Header color="white">{capitalize(translate("termsOfUseSingle"))}</Header>
            </Flex>

            <Flex flexBasis="min-content" w="100%" textAlign="left">
                <Button
                    ml={{ xs: "0", md: "4" }}
                    mt="4"
                    onClick={() =>
                        !!closeModal ? closeModal(modalNames.TERMS_AND_CONDITIONS_MODAL) : window.history.back()
                    }
                    variant="link"
                    size="md"
                    themeColor="blue"
                    _focus={{ boxShadow: "outline" }}
                >
                    {translate("back")}
                </Button>
            </Flex>
            <Flex
                w="100%"
                flexBasis="min-content"
                flexDirection="column"
                textAlign="left"
                p={{ xs: "8", lg: "12", xl: "24" }}
                borderBottom="1px solid"
                borderColor={"gray.200"}
                boxShadow={"md"}
            >
                {userLanguage === Languages.SV ? <TermsAndConditionsTextSv /> : <TermsAndConditionsTextEn />}
            </Flex>
        </Flex>
    );
};

export default TermsAndConditions;
