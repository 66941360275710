import React from "react";

import { Box, Header, ListItem, Text } from "Atoms";

export const TermsAndConditionsTextSv: React.FC = () => {
    return (
        <>
            <Header as="h1">Qopla användarvillkor för gäster</Header>
            <Text>Senast uppdaterad: Januari 2025</Text>
            <Box pt="4">
                <Header as="h2">1. Allmänt</Header>
                <Text>
                    Vi förbehåller oss rätten att skapa och tillämpa regler, processer, åtgärder och verktyg för att
                    säkerställa att plattformen används lagligt och i enlighet med våra policyer och dessa villkor. Om
                    plattformen används olagligt eller på ett sätt som strider mot våra policyer eller dessa villkor,
                    förbehåller vi oss rätten att granska och vidta åtgärder. Läs mer om våra rättigheter att agera mot
                    kunder i avsnitt 12. <br />
                    <br />
                    Genom att använda plattformen bekräftar du att du läst och godkänt dessa villkor samt att:
                    <ListItem>Du, om du beställer alkoholhaltiga drycker, har fyllt 18 år.</ListItem>
                    <ListItem>Du har rätt att ingå avtal; och</ListItem>
                    <ListItem>
                        Du är innehavare av det bank-/kreditkort eller bankkonto som används för köp på Plattformen.
                    </ListItem>
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">2. Plattformen och dess utbud</Header>
                <Text>
                    Qopla förmedlar matbeställningar från användaren till den valda restaurangen, nedan även kallat
                    Partnern. Varje restaurang är ansvarig för att bearbeta och leverera beställningen enligt sina egna
                    villkor. Qopla erbjuder inte egna leveranstjänster; leverans sker antingen genom avhämtning eller
                    restaurangens egna leveransalternativ. <br />
                    <br />
                    Qopla förmedlar enbart tjänsten mellan restaurangen och kunden. Avtal om köp ingås direkt mellan
                    kunden och restaurangen, vilket innebär att Qopla inte är part i avtalet. Qoplas förmedlar endast
                    transaktionen. Qopla har således inga skyldigheter för leverans, betalning eller andra villkor som
                    följer av avtalet mellan kunden och restaurangen, utan agerar endast som mellanhand i enlighet med
                    uppdragsavtalet.
                    <br />
                    <br />
                    Qopla ansvarar inte heller för:
                    <ListItem>Kvaliteten eller innehållet i maten.</ListItem>
                    <ListItem>Leveranser utförda av restauranger.</ListItem>
                    <ListItem>Eventuella fel i betalningshanteringen som orsakas av tredjepartsleverantörer.</ListItem>
                    <ListItem>Försenad eller misslyckad leverans på grund av tekniska problem.</ListItem>
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">3. Beställningar</Header>
                <Text>
                    På Plattformen kan du beställa produkter för främst avhämtning, äta på plats och, i de fall
                    restaurangen erbjuder det, hemleverans. Du kan göra beställningar på svenska och engelska, och när
                    du har lagt en beställning kommer en bekräftelse att skickas till den e-postadress du angav vid
                    betalningen. Bekräftelsen innehåller all information om beställningen samt eventuella ytterligare
                    villkor som gäller. <br />
                    <br />
                    Om du valt avhämtning är du ansvarig för att hämta beställningen på den plats som angivits vid
                    beställningstillfället. Om hemleverans erbjuds och du har valt detta, hanteras det av restaurangen
                    själva och Qopla tar inget ansvar för detta.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    3.1 Hur du genomför en beställning
                </Header>
                <Text>
                    Kunden besöker restaurangens webbplats och trycker på onlinebeställning och alla beställningar görs
                    på plattformen genom att du först väljer om du vill hämta maten eller äta på plats, eller om möjligt
                    levererat. Du kan också välja att göra en förbeställning för leverans eller avhämtning vid en senare
                    tidpunkt, upp till sju (7) dagar i förväg. Därefter väljer du de produkter du vill köpa, lägger dem
                    i din varukorg och genomför beställningen vid utcheckningen.
                    <br />
                    <br />
                    Observera att Qopla aldrig tar emot produktbeställningar via e-post eller chatt, och att betalningar
                    alltid sker på plattformen.
                    <br />
                    <br />
                    Efter att du har gjort en beställning får du ett e-postmeddelande som bekräftar att Qopla har
                    mottagit din beställning. Vi kommer att återkomma så snart beställningen har accepterats. När
                    beställningen har accepterats och avtalet är ingått, kommer detta att visas på plattformen. Där
                    kommer också den beräknade tiden för avhämtning att framgå. Vänligen notera att en beställning inte
                    kan avbrytas av dig efter att den har accepterats. Fram till dess kan du när som helst ändra eller
                    avbryta beställningen.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    3.2 Speciella instruktioner vid beställning
                </Header>
                <Text>
                    Du har rätt att lämna speciella instruktioner för en beställning eller en Produkt/-er i
                    beställningen. I den utsträckning det är möjligt och kommersiellt rimligt kommer dessa speciella
                    instruktioner att efterföljas. Varken Partnern eller Qopla är dock skyldig att byta ut eller
                    återbetala en beställning och/eller en Produkt/-er som åtföljts av speciella instruktioner från Dig.
                    <br />
                    <br />
                    För speciella instruktioner relaterade till allergener, allergiska reaktioner, restriktioner eller
                    krav i kostplaner, var vänlig kontakta den relevanta Partnern direkt enligt avsnitt 7.2–3 nedan
                    innan Du lägger beställningen. För det fall Du är särskilt känslig för kontakt med vissa allergener,
                    vänligen notera att varken Qopla eller Partnern kan garantera att Din Produkt, trots eventuell
                    särskild paketering, inte levereras i samma transportväska som Produkter som innehåller sådana
                    allergener.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    3.3 Ångerrätt och reklamation
                </Header>
                <Text>
                    I enlighet med distansavtalslagen gäller ångerrätten inte köp av måltider, livsmedel eller andra
                    Produkter med kort hållbarhet eller som snabbt kan försämras eller bli för gamla, eller köp av
                    produkt som var förseglad vid leverans men där förseglingen därefter har brutits av Dig och
                    Produkten inte lämpligen kan återlämnas pga. hälso- eller hygienskäl; Genom att göra en beställning,
                    ger du Ditt uttryckliga samtycke till att leveranstjänsten kommer påbörjas så snart Din beställning
                    har accepterats och att Du därmed går med på att ångerrätten är förverkad när tjänsten fullgjorts.
                    <br />
                    <br />
                    Reklamation av felaktiga eller defekta varor ska ske inom skälig tid efter att felet upptäckts. Se
                    vidare under avsnitt 8 nedan.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">4. Priser och Avgifter</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    4.1 Pris
                </Header>
                <Text>
                    Aktuella priser för produkter, leveransavgifter och serviceavgifter visas på plattformen när du
                    besöker en restaurangpartners sida. Qopla reserverar sig för eventuella tydliga fel i priser eller
                    annan information. Alla priser på plattformen är inklusive moms.
                    <br />
                    <br />
                    Priserna kan påverkas av erbjudanden som kan vara tillgängliga för specifika partners, de beställda
                    produkterna, produktkategorin eller beställningens belopp, och kan erbjudas på bekostnad av Qopla
                    eller partnern (mer information finns i avsnitt 5). Dessutom kan Qopla erbjuda bonusprogram,
                    kampanjer eller liknande från tid till annan.
                    <br />
                    <br />
                    Qopla kan i vissa fall själva sätta priset på produkter från partners och kan även begränsa partners
                    möjlighet att erbjuda produkter på plattformen. Qopla har rätt att uppdatera priser och/eller
                    innehåll och annan information om produkterna. Det är ditt ansvar att kontrollera vilka priser och
                    villkor som gäller vid den tidpunkt då du gör din beställning.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    4.2 Minsta ordervärde
                </Header>
                <Text>
                    Vid köp via plattformen kan det finnas ett minimibelopp för beställningar. Detta belopp kan variera
                    mellan olika partners men visas alltid i din varukorg innan du slutför beställningen. Om värdet på
                    din beställning är lägre än minimibeloppet, betalar du mellanskillnaden. Detta belopp kan dras av
                    från en eventuell återbetalning vid retur.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">5. Betalning</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    5.1 Hur betalning går till
                </Header>
                <Text>
                    Om inte annat avtalats, ska betalning för din beställning göras till Qopla vid
                    beställningstillfället med bank-/kreditkort, Swish eller andra betalningsmetoder som erbjuds på
                    plattformen. Giltiga värdekuponger eller presentkort kan också användas för att betala hela eller
                    delar av din beställning. De tillgängliga betalningsmetoderna visas vid kassan.
                    <br />
                    <br />
                    Observera att Qopla inte kan garantera att alla betalningsmetoder alltid är tillgängliga och att de
                    ibland kan vara tillfälligt otillgängliga eller begränsade. Qopla förbehåller sig rätten att erbjuda
                    fler betalningsmetoder och/eller ta bort befintliga betalningsmetoder när som helst.
                    <br />
                    <br />
                    Med ditt samtycke kan dina bank-/kreditkort- eller betalningsuppgifter lagras hos våra
                    tredjepartsbetalningsleverantörer för framtida beställningar. Qopla lagrar dock inte dessa
                    uppgifter.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    5.2 Inga egna betaltjänster
                </Header>
                <Text>
                    Qopla tillhandahåller inte egna betaltjänster till dig eller till tredje part. Alla
                    onlinebetalningar behandlas via de externa betalningstjänstleverantörer som presenteras på
                    Plattformen. Genom att göra ett köp via Plattformen accepterar du därför, utöver dessa Villkor, även
                    de villkor för den externa betalningstjänstleverantören som är relevant för din betalning. För mer
                    information, besök respektive externa betalningstjänstleverantörs webbplats.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    5.3 Presentkort och rabattkoder
                </Header>
                <Text>
                    Qopla erbjuder presentkort och rabattkoder.
                    <br />
                    <br />
                    <strong>Presentkort:</strong> Digitala presentkort kan köpas och användas för beställningar via
                    plattformen. De är giltiga under den period som anges vid utfärdandet och kan inte lösas in mot
                    kontanter. Qopla håller inte pengarna för presentkortssaldot, utan dessa betalas direkt till berörd
                    restaurang.
                    <br />
                    <br />
                    <strong>Rabattkoder:</strong> Rabattkoder kan erbjudas för specifika kampanjer och tidsperioder.
                    Villkoren för varje rabattkod anges vid utfärdandet.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">6. Villkor för köp </Header>
                <Text>
                    Om du har valt avhämtning eller att äta på plats, ska produkten/-erna hämtas av dig på partnerns
                    restaurang vid den tid som anges vid beställningstillfället på plattformen. Beroende på vilken typ
                    av produkt/-er du har beställt, kan partnern kräva att du identifierar dig med giltig legitimation
                    vid upphämtningen. Partnern kan även neka utlämning om vissa villkor föreligger.
                    <br />
                    <br />
                    Vissa produkter kan försämras om du inte hämtar dem vid den angivna tiden. Qopla, eller i
                    tillämpliga fall partnern, ansvarar inte för eventuella försämringar som kan uppstå om produkterna
                    inte hämtas i tid.
                    <br />
                    <br />
                    Om beställningen inte hämtas inom rimlig tid, oftast 30 minuter efter den angivna upphämtningstiden,
                    kan du debiteras fullt pris för produkterna om de inte kan säljas på nytt och/eller för andra
                    kostnader som Qopla eller partnern haft i samband med din beställning.
                    <br />
                    <br />
                    När du hämtat upp beställningen övergår risken för förlust eller skada på produkterna till dig.
                    Varken Qopla eller partnern är ansvariga för eventuell förlust eller skada på produkterna som
                    uppstår efter upphämtning.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">7. Innehåll och Kvalitet</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    7.1 Allmänt
                </Header>
                <Text>
                    Måltider och andra livsmedel som erbjuds på plattformen förbereds och förpackas av våra partners.
                    Qopla är inte inblandade i förberedelsen, tillagningen eller paketeringen av produkterna. Partnern
                    ansvarar för att vara registrerad som livsmedelsanläggning hos lokala myndigheter och att följa
                    Livsmedelsverkets krav.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    7.2 Information om innehåll
                </Header>
                <Text>
                    Den restaurang som tillagar eller förbereder måltider via plattformen tillhandahåller en lista över
                    ingredienser och annan information om måltiden. Detta presenteras på plattformen, men Qopla kan inte
                    garantera att informationen alltid är korrekt och uppdaterad eftersom restaurangen kan ha ändrat
                    eller lagt till ingredienser utan vår vetskap. För mer information om exempelvis allergener,
                    ingredienser eller råvarans ursprung, vänligen kontakta restaurangen direkt via kontaktuppgifterna
                    på respektive restaurangs partnersida genom att klicka på "Kontakta restaurangen". Om inget annat
                    anges, är måltiderna avsedda att förtäras omedelbart efter leverans och vi ansvarar inte för
                    måltidens kvalitet om den äts senare.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    7.3 Särskilda villkor för produkter
                </Header>
                <Text>
                    Produkter med åldersbegränsning, som alkohol, får endast beställas av dig om du är 18 år eller
                    äldre. Vid servering “Äta här” kommer restaurangen att genomföra en ålders- och ID-kontroll av
                    mottagaren för att bekräfta att denne är samma person som beställaren. Om mottagaren inte godkänns
                    vid ålders- och ID-kontrollen, kommer varken Qopla eller restaurangen att leverera/utlämna produkten
                    och den kommer att återlämnas till restaurangen. Om beställningen innehåller andra produkter som
                    inte har åldersbegränsning, kan dessa dock levereras/utlämnas.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">8. Reklamation</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    8.1 Reklamation
                </Header>
                <Text>
                    Qopla hänvisar alla reklamationsärenden direkt till partnerrestaurangen. Om restaurangen inte
                    svarar, tar Qopla ett beslut om reklamationen. Det är dock i första hand alltid restaurangens ansvar
                    att hantera reklamationer och ångerrätt i och med avtal om köp ingås direkt mellan kunden och
                    restaurangen.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    8.2 Återbetalning av returer
                </Header>
                <Text>
                    Efter att Qopla eller partnern har godkänt din retur, sker återbetalning med samma betalningsmetod
                    som du använde vid beställningen, om inte annat har avtalats eller framgår av dessa villkor. Om
                    produkten vid retur har använts, hanterats eller förpackats oaktsamt av dig, eller om du hanterat
                    produkten mer än nödvändigt för att fastställa dess egenskaper eller funktion, har Qopla eller
                    partnern rätt att dra av ett belopp motsvarande produktens värdeminskning från det belopp som ska
                    återbetalas.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">9. Kundrecensioner</Header>
                <Text>
                    Kunder har möjlighet att betygsätta och lämna omdömen om restauranger och produkter. För att
                    säkerställa att betyg och omdömen kommer från kunder som faktiskt har köpt produkter från den
                    aktuella restaurangen, kan kunder endast betygsätta och skriva omdömen efter att beställningen har
                    levererats och endast under en begränsad tid.
                    <br />
                    <br />
                    Recensioner som lämnas på plattformen måste vara fria från rasistiskt, sexistiskt, kränkande,
                    trakasserande eller på annat sätt hotfullt eller olagligt innehåll. De får inte heller inkräkta på
                    tredje parts rättigheter (inklusive immateriella rättigheter). Vi granskar recensioner och om en
                    recension enligt vårt omdöme, eller efter en anmälan enligt avsnitt 9, bedöms vara olaglig, strida
                    mot dessa villkor eller annars inte överensstämma med våra värderingar, har vi rätt att ta bort den
                    från plattformen. Om en kund ofta lämnar recensioner med uppenbart olagligt innehåll, har vi
                    dessutom rätt att tillfälligt stänga av kunden från plattformen efter att en varning har utfärdats.
                    <br />
                    <br />
                    Vi kommer alltid att ge kunden en tydlig och specifik motivering till vårt beslut om moderering. En
                    tillfällig avstängning kommer i första hand att pågå i högst 15 dagar. Om beteendet bedöms som
                    allvarligt, kan vi besluta att stänga av kunden permanent.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">10. Personuppgiftsbehandling och dataskydd</Header>
                <Text>
                    Vi behandlar dina personuppgifter i enlighet med Dataskyddsförordningen (GDPR) som du kan läsa mer
                    om i vår integritetspolicy. Genom att godkänna villkoren är du medveten om att vi kan skicka e-post
                    och/eller sms till dig med anknytning till din beställning.
                    <br />
                    <br />
                    Vi samlar in, lagrar och behandlar dina personuppgifter endast i syfte att leverera våra tjänster
                    och förbättra din användarupplevelse.
                    <br />
                    <br />
                    Beroende på val av betalningsmetod kan även extern betaltjänstleverantör komma att behandla dina
                    personuppgifter enligt deras egna villkor.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">11. Qoplas rättigheter</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    11.1 Respekt för Plattformen
                </Header>
                <Text>
                    Produkter som säljs via plattformen är avsedda för personligt bruk, och kommersiell användning av
                    produkterna eller plattformen är strikt förbjuden. Genom att godkänna dessa villkor förbinder du dig
                    att inte sälja vidare eller överlåta några rättigheter eller skyldigheter i relation till Qopla.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    11.2 Respekt för andra immateriella rättigheter
                </Header>
                <Text>
                    Alla upphovsrätter, varumärken, varunamn, logotyper och andra immateriella eller industriella
                    rättigheter som tillhör oss, våra licensgivare eller våra partners, och som finns på plattformen
                    eller i Qoplas marknadsföringsmaterial, får inte återskapas, distribueras, säljas, användas, postas,
                    publiceras, överföras, modifieras, ändras, kopieras, eller användas utan vårt skriftliga tillstånd.
                    <br />
                    <br />
                    Användning av dessa rättigheter i kommersiella syften eller på annat sätt utan vårt godkännande är
                    strängt förbjudet, utom i den mån det krävs för att använda plattformen enligt dessa villkor.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    11.3 Respekt för egendom
                </Header>
                <Text>
                    Du får inte, oavsett ändamål, manipulera, försöka få obehörig åtkomst till, ändra, hacka eller på
                    annat sätt justera vår egendom, inklusive vårt material, vår mjukvara, hårdvara, källkod eller
                    information.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">12. Qoplas rätt att agera mot kunder</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    12.1 Tillfällig eller omedelbar avstängning
                </Header>
                <Text>
                    Om du ofta tillhandahåller innehåll på plattformen som vi, efter vårt eget omdöme eller efter en
                    anmälan, bedömer som uppenbart olagligt (till exempel kränkande, trakasserande, hatpropaganda eller
                    som på annat sätt tydligt bryter mot dessa villkor), förbehåller vi oss rätten att tillfälligt
                    stänga av dig från plattformen under en rimlig tidsperiod efter att ha utfärdat en förhandsvarning.
                    Vi kommer alltid att ge dig en tydlig och specifik motivering till vårt beslut, och avstängningen
                    kommer i första hand att pågå i högst 15 dagar. Om ditt beteende bedöms allvarligt kan vi besluta
                    att stänga av dig permanent, och du kommer alltid att få ett e-postmeddelande om detta. Oavsett
                    beteendets allvar kan vi alltid kräva ersättning för kostnader och skador som åsamkats oss eller
                    våra partners till följd av ditt beteende.
                    <br />
                    <br />
                    Vi förbehåller oss också rätten att stänga av konton och avsluta pågående eller förbeställda
                    beställningar med omedelbar verkan om du bryter mot dessa villkor, missbrukar våra tjänster, eller
                    på annat sätt orsakar skada eller besvär för oss, våra partners eller våra övriga kunder genom
                    användningen av plattformen och våra tjänster. Till exempel om vi har rimliga skäl att misstänka att
                    du lägger upprepade falska beställningar eller reklamationer. Avstängning av konton kan ske
                    omedelbart, och du får alltid ett meddelande via e-post. Vid avstängning kommer vi att ersätta dig
                    för ej färdigställda beställningar, reducerat med de kostnader som vi eller partnern åsamkats på
                    grund av avstängningen.
                    <br />
                    <br />
                    Slutligen förbehåller vi oss rätten att inaktivera användningen av e-postadresser och/eller
                    betalningsuppgifter som tidigare länkats till misstänkta eller bekräftade bedrägerier på
                    plattformen.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">13. Övrigt</Header>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    13.1 Plattformens tillgänglighet
                </Header>
                <Text>
                    Vi strävar efter att ge dig den bästa möjliga kundupplevelsen, men vi kan inte garantera att
                    plattformen alltid är säker, tillgänglig, oavbruten eller fri från fel, tekniska problem, defekter
                    eller virus. Tillfälliga störningar kan uppstå på plattformen på grund av planerat underhåll,
                    internet- eller kommunikationsavbrott, force majeure eller andra faktorer utanför vår kontroll. Vi
                    ansvarar inte för förseningar, misslyckade leveranser, fel, förluster eller skador som uppstår på
                    grund av sådana problem, inklusive problem som naturligt förekommer vid användning av plattformen,
                    internet eller elektronisk kommunikation.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h3" fontSize="1.5em">
                    13.2 Ändring av villkor
                </Header>
                <Text>
                    Qopla förbehåller sig rätten att när som helst ändra dessa villkor. Vi kommer att meddela dig om
                    ändringar genom att publicera uppdaterade villkor på vår webbplats och i mobilappen. Den fortsatta
                    användningen av våra tjänster efter att ändringarna trätt i kraft innebär att du accepterar de nya
                    villkoren.
                    <br />
                    <br />
                    Du förstår och accepterar att vi regelbundet uppdaterar plattformen, inklusive vår webbplats, för
                    att säkerställa dess säkerhet och förbättra din användarupplevelse. Dessa villkor gäller även efter
                    att sådana uppdateringar av mobilappen har genomförts.
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">14. Tillämplig lag och tvistlösning</Header>
                <Text>
                    Villkoren är upprättade i enlighet med svensk lag och ska tolkas enligt denna.
                    <br />
                    <br />
                    Tvister mellan dig och Qopla ska i första hand lösas genom överenskommelse. Om vi inte kan enas och
                    du har köpt produkter via plattformen som privatperson/konsument, ska tvisten avgöras av Allmänna
                    Reklamationsnämnden (ARN). Qopla följer ARN:s rekommendationer. I andra fall ska tvisten slutligt
                    avgöras av allmän domstol.
                    <br />
                    <br />
                    Som privatperson/konsument bosatt inom EU har du också rätt att använda EU-kommissionens
                    ODR-plattform (Online Dispute Resolution) för att lösa tvister via internet. ODR-plattformen finns{" "}
                    <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">här.</a>
                </Text>
            </Box>
            <Box pt="12">
                <Header as="h2">15. Kontaktuppgifter</Header>
                <Text>
                    Om Du har några frågor om Plattformen, Produkter eller beställningar eller om Du upplever några
                    problem med Ditt konto, är Du välkommen att kontakta vår kundservice via e-post på support@qopla.com
                    efter att ett köp gjorts.
                </Text>
            </Box>
        </>
    );
};
